import type { UserDocument } from '@readme/backend/models/user/types';
import type { Document } from 'mongoose';

export const GitSyncProviderName = {
  github: 'github',
  github_enterprise_server: 'github_enterprise_server',
} as const;

export type GitSyncProviderNameUnion = (typeof GitSyncProviderName)[keyof typeof GitSyncProviderName];

export const GitSyncProviderNameArray = Object.values(GitSyncProviderName);

export const GitSyncVisibility = {
  public: 'public',
  private: 'private',
  internal: 'internal',
} as const;

export type GitSyncVisibilityUnion = (typeof GitSyncVisibility)[keyof typeof GitSyncVisibility];

export const GitSyncVisibilityArray = Object.values(GitSyncVisibility);

export const GitSyncGitHubOwnerType = {
  Organization: 'Organization',
  User: 'User',
} as const;

export type GitSyncGitHubOwnerTypeUnion = (typeof GitSyncGitHubOwnerType)[keyof typeof GitSyncGitHubOwnerType];

export const GitSyncGitHubOwnerTypeArray = Object.values(GitSyncGitHubOwnerType);

export interface GitSyncGithubOwner {
  id: number;
  login: string;
  site_admin?: boolean;
  type: GitSyncGitHubOwnerTypeUnion;
}

interface GitSyncProviderBase {
  _id: Document['id'];
  created_at: Date;
  created_by: UserDocument;
  error?: {
    code?: string;
    message?: string;
  } | null;
  type: GitSyncProviderNameUnion;
  updated_at: Date;
}

// Properties fetched from:
// https://docs.github.com/en/rest/apps/apps?apiVersion=2022-11-28#create-a-github-app-from-a-manifest
export interface GitSyncProviderGitHubEnterpriseServer extends GitSyncProviderBase {
  app_id: number;
  client_id: string;
  client_secret: string;
  events: string[];
  hostname: string;
  html_url: string;
  name: string;
  owner: GitSyncGithubOwner;
  pem: string;
  permissions: Record<string, string | undefined>;
  protocol: string;
  slug?: string;
  type: 'github_enterprise_server';
  webhook_secret: string;
}

export type GitSyncProvider = GitSyncProviderGitHubEnterpriseServer;

interface GitSyncConnectionBase {
  _id?: Document['id'];
  active?: boolean;
  created_at: Date;
  created_by?: UserDocument;
  error?: {
    code?: string;
    message?: string;
  };
  provider_type: GitSyncProviderNameUnion;
  updated_at?: Date;
}

interface GitSyncConnectionWithProviderBase extends GitSyncConnectionBase {
  provider: Document['id'];
}

export interface GitSyncConnectionGitHub extends GitSyncConnectionBase {
  events?: string[];
  installation_id: number;
  owner: GitSyncGithubOwner;
  permissions?: Record<string, string>;
  provider_type: 'github';
}

export interface GitSyncConnectionGitHubEnterpriseServer extends GitSyncConnectionWithProviderBase {
  events?: string[];
  installation_id: number;
  owner: GitSyncGithubOwner;
  permissions?: Record<string, string>;
  provider_type: 'github_enterprise_server';
}

export type GitSyncConnection = GitSyncConnectionGitHub | GitSyncConnectionGitHubEnterpriseServer;

export const isGitSyncConnectionGitHub = (
  connection?: GitSyncConnection | null,
): connection is GitSyncConnectionGitHub => {
  if (!connection) {
    return false;
  }
  return 'provider_type' in connection && connection.provider_type === GitSyncProviderName.github;
};

export const isGitSyncConnectionGitHubEnterpriseServer = (
  connection?: GitSyncConnection | null,
): connection is GitSyncConnectionGitHubEnterpriseServer => {
  if (!connection) {
    return false;
  }
  return 'provider_type' in connection && connection.provider_type === GitSyncProviderName.github_enterprise_server;
};

export const isGitSyncProviderGitHubEnterpriseServer = (
  provider?: GitSyncProvider | null,
): provider is GitSyncProviderGitHubEnterpriseServer => {
  if (!provider) {
    return false;
  }
  return 'type' in provider && provider.type === GitSyncProviderName.github_enterprise_server;
};

export const isGitSyncConnectionWithProvider = (
  connection?: GitSyncConnectionBase | GitSyncConnectionWithProviderBase | null,
): connection is GitSyncConnectionWithProviderBase => {
  if (!connection) {
    return false;
  }
  return 'provider' in connection && !!connection.provider;
};

// The linked repository is the selected repository that a
// ReadMe project is actively syncing with
interface GitSyncLinkedRepositoryBase {
  error?: {
    code?: string;
    message?: string;
  };
  linked_at: Date;
  linked_by: UserDocument;
  provider_type: GitSyncProviderNameUnion;
}

export interface GitSyncLinkedRepositoryGithub extends GitSyncLinkedRepositoryBase {
  connection: Document['id'];
  full_name: string;
  id: string;
  name: string;
  privacy: {
    private: boolean;
    visibility: GitSyncVisibilityUnion;
  };
  provider_type: 'github';
  url: string;
}

export interface GitSyncLinkedRepositoryGithubEnterpriseServer extends GitSyncLinkedRepositoryBase {
  connection: Document['id'];
  full_name: string;
  id: string;
  name: string;
  privacy: {
    private: boolean;
    visibility: GitSyncVisibilityUnion;
  };
  provider_type: 'github_enterprise_server';
  url: string;
}

export type GitSyncLinkedRepository = GitSyncLinkedRepositoryGithub | GitSyncLinkedRepositoryGithubEnterpriseServer;

export const isGitSyncLinkedRepositoryGitHub = (
  linkedRepository: GitSyncLinkedRepository,
): linkedRepository is GitSyncLinkedRepositoryGithub => {
  return 'provider_type' in linkedRepository && linkedRepository.provider_type === GitSyncProviderName.github;
};

export const isGitSyncLinkedRepositoryGitHubEnterpriseServer = (
  linkedRepository: GitSyncLinkedRepository,
): linkedRepository is GitSyncLinkedRepositoryGithubEnterpriseServer => {
  return (
    'provider_type' in linkedRepository &&
    linkedRepository.provider_type === GitSyncProviderName.github_enterprise_server
  );
};

interface GitSyncSetupBase {
  error?: {
    code?: string;
    message?: string;
  };
  provider_type: GitSyncProviderNameUnion;
  setup_by: UserDocument;
  verify_sync_hash: string;
}

export interface GitSyncSetupGitHub extends GitSyncSetupBase {
  provider_type: 'github';
}

export interface GitSyncSetupGitHubEnterpriseServer extends GitSyncSetupBase {
  hostname: string;
  organization: string;
  provider_id: Document['id'];
  provider_type: 'github_enterprise_server';
}

export type GitSyncSetup = GitSyncSetupGitHub | GitSyncSetupGitHubEnterpriseServer;
